<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <div @click="$router.replace('/')" class="icon">
              <ModalCloseIcon :dark="true" />
            </div>
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="content">
              <div class="logo">
                <img src="../assets/logo-n.svg" alt="Nvoko Logo" />
              </div>
              <h3>
                Reset your Password.
              </h3>
              <div class="inputs">
                <div class="form-group">
                  <input v-model="password" id="password" type="password" class="text-input dark"
                    placeholder="Password" />
                </div>
              </div>
              <button @click="reset()" class="primary" :disabled="!password || submitting">
                {{ submitting ? "Loading..." : "Reset Password" }}
              </button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import RESET_PASSWORD from "@/api/mutations/RESET_PASSWORD.gql";
import GET_DEFAULT_ARTIST from "@/api/queries/GET_DEFAULT_ARTIST.gql";

export default {
  name: "ResetPassword",
  components: { ModalCloseIcon },
  data() {
    return {
      password: null,
      submitting: false
    };
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    user() {
      return this.$store.getters["account/getUser"];
    }
  },
  methods: {
    async reset() {
      try {
        this.submitting = true;
        const result = await this.$apollo.mutate({
          mutation: RESET_PASSWORD,
          variables: {
            token: this.token,
            password: this.password
          }
        });
        const { reset_password } = result.data;
        this.$token = reset_password.token;
        this.$store.commit("account/authSuccess", reset_password);
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.submitting = false;
      }
    }
  },
  apollo: {
    artist: {
      query: GET_DEFAULT_ARTIST,
      variables() {
        return { userId: this.user.id };
      },
      update({ artists }) {
        this.$store.commit("account/updateArtist", artists[0]);
        if (this.user.status === "unverified")
          this.$router.push({ name: "SignupVerification" });
        else if (!this.user.onboarding_complete)
          this.$router.push({ name: "Onboarding" });
        else this.$router.push({ name: "Catalog" });
      },
      skip() {
        return !(this.user || {}).id;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 32px;
}

.modal-body {
  width: 100%;
  height: calc(100vh - 164px);
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 350px;

    .logo {
      margin-bottom: 32px;
    }

    h3 {
      color: $white;
      font-weight: 300;
      margin-bottom: 52px;
    }

    .inputs {
      width: 100%;

      .form-group {
        margin-bottom: 24px;

        input {
          @include details-light();
          color: $white;
        }

        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          letter-spacing: -0.22px;
          color: #fcfffc;
        }
      }

      .names {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        input {
          width: 48%;
        }
      }

      .styled-checkbox {
        position: absolute;
        opacity: 0;

        &+label {
          display: flex;
          position: relative;
          cursor: pointer;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          letter-spacing: -0.22px;
          color: #fcfffc;
        }

        &+label:before {
          content: "";
          margin-top: 2px;
          margin-right: 8px;
          display: inline-block;
          vertical-align: text-top;
          width: 14px;
          height: 14px;
          background: transparent;
          border: 1px solid white;
        }

        &:focus+label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        &:checked+label:before {
          background: transparent;
        }

        &:disabled+label {
          color: #b8b8b8;
          cursor: auto;
        }

        &:disabled+label:before {
          box-shadow: none;
          background: #ddd;
        }

        &:checked+label:after {
          content: "";
          position: absolute;
          left: 2px;
          top: 6px;

          width: 2px;
          height: 2px;
          margin-top: 2px;
          background: white;
          box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
            4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }

    button {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.22px;
      padding: 10px 30px 12px;
    }
  }
}
</style>
